import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppContext } from "./AppContext";
import FrontScreen from "./Screens/FrontScreen";
import EleccionInicial from "./Screens/EleccionInicial";
import Coberturas from "./Screens/Coberturas";
import InputPlacas from "./Screens/InputPlacas";
import ConfirmarPlacas from "./Screens/ConfirmarPlacas";
import TarjetaCirculacion from "./Screens/TarjetaCirculacion";
import IneAtras from "./Screens/IneAtras";
import InputInformation from "./Screens/InputInformation";
import InfoResults from "./Screens/InfoResults";
import TablaFila from "./Screens/TablaFila";
import axios from "axios";
import ComingSoon from "./Screens/ComingSoon";
import IneEnfrente from "./Screens/IneEnfrente";
import parseIneInfo from "./utils/parseIneInfo";
import Trabajador from "./Screens/Trabajador";
import { useNavigate } from "react-router-dom";
import UploadAndWait from "./Screens/Upload&Wait";
import AseguradoraEleccion from "./Screens/AseguradoraEleccion";
import VehiculoEleccion from "./Screens/VehiculoEleccion";
import GeneroEleccion from "./Screens/GeneroEleccion";
 
const pica = require("pica")();

const App: React.FC = () => {
  const navigate = useNavigate();
  const alldata = useAppContext();

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      (async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((i) => i.kind === "videoinput");
        alldata.setDevices(videoDevices);
      })();
    } else {
      console.error("Media devices API not supported.");
    }
  }, [alldata]);

  return (
    <Routes>
      <Route path="/" element={<ComingSoon />} />
      <Route path="/Trabajador" element={<Trabajador />} />
      <Route
        path="/TablaFila/*"
        element={
          <TablaFila
            data={[
              {
                numero: "1",
                pagado: false,
                apellidos: alldata.parsedData?.apellidos || "",
                nombres: alldata.parsedData?.nombres || "",
                cobertura: "Cobertura Amplia",
                monto: "$1,210.00",
                clave: "0",
                placas: alldata.plateNumber || "",
                serie: alldata.scrapedData?.serie || "",
                origen: alldata.scrapedData?.origen || "",
                marca: alldata.scrapedData?.marca || "",
                linea: alldata.scrapedData?.linea || "",
                version: alldata.scrapedData?.version || "",
                modelo: alldata.scrapedData?.modelo || "",
                domicilio: alldata.parsedData?.domicilio || "",
                colonia: alldata.parsedData?.colonia || "",
                codigoPostal: alldata.parsedData?.codigoPostal || "",
                telefono: alldata.inputInfo?.telefono || "",
                correo: alldata.inputInfo?.correo || "",
                rfc: alldata.inputInfo?.rfc || "",
                numeroPoliza: "POL-001",
                polizaArchivo: "/path/to/poliza/file.pdf",
                INE_front: alldata.ineEnfrentePhoto || "",
                INE_back: alldata.ineAtrasPhoto || "",
                TarjetaCirculacion: alldata.circulacionPhoto || "",
              },
            ]}
          />
        }
      />
      <Route path="/FrontScreen/*" element={<FrontScreen />} />
      <Route
        path="/EleccionInicial/*"
        element={
          <EleccionInicial
            onSelect={(selection) =>
              selection === "obligatoria" ? "coberturas" : "eleccionInicial"
            }
          />
        }
      />
      <Route path="/AseguradoraEleccion/*" element={<AseguradoraEleccion />} />
      <Route path="/VehiculoEleccion/*" element={<VehiculoEleccion />} />
      <Route path="/GeneroEleccion/*" element={<GeneroEleccion />} />
      <Route path="/Coberturas/*" element={<Coberturas />} />
      <Route
        path="/InputPlacas/*"
        element={
          <InputPlacas
            onSubmit={(plateNumber, data) => {
              alldata.setPlateNumber(plateNumber);
              alldata.setScrapedData(data);
              navigate("/ConfirmarPlacas/*");
            }}
          />
        }
      />
      <Route
        path="/ConfirmarPlacas/*"
        element={
          <ConfirmarPlacas
            data={alldata.scrapedData}
            onConfirm={(confirmed) => {
              if (confirmed) {
                navigate("/TarjetaCirculacion/");
              } else {
                navigate("/InputPlacas/");
              }
            }}
          />
        }
      />
      <Route
        path="/TarjetaCirculacion/*"
        element={
          <TarjetaCirculacion
            onCapture={(photo: string) => {
              axios
                .post("https://gruporomeroseguros.com/photo", { photo })
                .then(async (response) => {
                  console.log("Response Data:", response.data);
                  alldata.setCirculacionPhoto(photo);
                  alldata.setCirculacionPhotoNombre(response.data.toString());
                })
                .finally(() => {
                  console.log(alldata);
                  navigate("/IneEnfrente/");
                });
            }}
          />
        }
      />
      <Route
        path="/IneEnfrente/*"
        element={
          <IneEnfrente
            onCapture={async (photo: string) => {
              console.log("DATA BEFORE AI",alldata);
              const width = 1920;
              const height = 1080;
              const htmlimage = new Image(width, height);
              const outputCanvas = document.createElement("canvas");
              htmlimage.src = photo;
              outputCanvas.width = 1280;
              outputCanvas.height = 720;
              try {
                await pica.resize(htmlimage, outputCanvas);
              } catch (error) {
                console.error("Error resizing image:", error);
              }
              try {
                const base64String = outputCanvas.toDataURL();
                axios.post(
                  "https://gruporomeroseguros.com/ai",
                  { photo: base64String },
                  {
                    headers: { "Content-Type": "application/json" },
                  }
                ).then((response)=>{
                  console.log("ai response:", response);
                  parseIneInfo(response.data.text).then((parsedData)=>{
                    alldata.setParsedData(parsedData);
                    alldata.setIneEnfrentePhoto(photo);
                    alldata.setIneEnfrentePhotoNombre(response.data.filename.toString());
                  }).finally(()=>{
                    navigate("/IneAtras/");
                  }).catch((error)=>{
                    console.error("Error parsing INE info:", error);
                  });
                }).catch((error)=>{
                  console.error("Error ai sending:", error);
                });
              } catch (error) {
                console.error("Error parsing INE info:", error);
              }
            }}
          />
        }
      />
      <Route
        path="/IneAtras/*"
        element={
          <IneAtras
            onCapture={async (photo: string) => {
              try {
                await axios
                  .post("https://gruporomeroseguros.com/photo", { photo })
                  .then(async (response) => {
                    console.log("Response Data:", response.data);
                    alldata.setIneAtrasPhoto(photo);
                    alldata.setIneAtrasPhotoNombre(response.data.toString());
                  })
                  .finally(() => {
                    navigate("/InputInformation/");
                  });
              } catch (error) {
                console.error("Error uploading photo:", error);
              }
            }}
          />
        }
      />
      <Route
        path="/InputInformation/*"
        element={
          <InputInformation
            onSubmit={(data) => {
              alldata.setInputInfo(data);
             
              navigate("/InfoResults/");
            }}
          />
        }
      />
      <Route
        path="/InfoResults/*"
        element={
          <InfoResults
            data={{
              telefono: alldata.inputInfo?.telefono || "",
              correo: alldata.inputInfo?.correo || "",
              rfc: alldata.inputInfo?.rfc || "",
              placa: alldata.scrapedData?.placa || "",
              serie: alldata.scrapedData?.serie || "",
              origen: alldata.scrapedData?.origen || "",
              marca: alldata.scrapedData?.marca || "",
              linea: alldata.scrapedData?.linea || "",
              version: alldata.scrapedData?.version || "",
              modelo: alldata.scrapedData?.modelo || "",
              tarjetaCirculacionPhoto: alldata.circulacionPhoto,
              ineAtrasPhoto: alldata.ineAtrasPhoto,
            }}
            onModify={() => {
              navigate("/FrontScreen/");
            }}
            onConfirm={() => {
              console.log("after InputInformation ", alldata);
              navigate("/UploadAndWait/");
            }}
          />
        }
      />
      <Route path="/UploadAndWait/*" element={<UploadAndWait />} />
    </Routes>
  );
};

export default App;
