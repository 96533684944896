import React from "react";
import axios from "axios";
import { useAppContext } from "../AppContext";

const UploadAndWait: React.FC = () => {
  const alldata = useAppContext();
  const bdate = "18/09/1996";
  const [day, month, year] = bdate.split("/").map((part) => parseInt(part, 10));
  const date = new Date(year, month - 1, day);
  function getFormattedTimestamp(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  function getFormatteddate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const customTimestamp = getFormattedTimestamp(date);
  const customDate = getFormatteddate( new Date());
  return (
    <div style={styles.container}>
      <>
        <p style={styles.text}>
          presione boton para pedir turno de atencion...
        </p>
        <button
          onClick={async () => {
            // Send a POST request
            let amisresponse;
            //axios call backend to get amis depending onf insurance company and car model
            if (alldata.aseguradora === "Qualitas") {
              axios
                .post("https://www.gruporomeroseguros.com/amis/", {
                  modelo: alldata.scrapedData?.modelo,
                  marca: alldata.scrapedData?.marca,
                  linea: alldata.scrapedData?.linea,
                  cartype: alldata.tipovehiculo,
                  aseguradora: alldata.aseguradora,
                })
                .then((response) => {
                  console.log("response", response.data);
                  amisresponse = response.data;
                });
            }
            if (alldata.aseguradora === "Ana") {
              axios
                .post("https://www.gruporomeroseguros.com/amis/", {
                  modelo: alldata.scrapedData?.modelo,
                  marca: alldata.scrapedData?.marca,
                  linea: alldata.scrapedData?.linea,
                  cartype: alldata.tipovehiculo,
                  aseguradora: alldata.aseguradora,
                })
                .then((response) => {
                  console.log("response", response.data);
                  amisresponse = response.data;
                });
            }
            let gener  = alldata.parsedData?.genero === "Hombre" ? true : false;
            console.log("data/;", { apellidos: alldata.parsedData?.apellidos,
              cAmis: amisresponse,
              cartype: alldata.tipovehiculo,
              ciudad: alldata.parsedData?.ciudad,
              cobertura: "Obligatoria",
              codigoPostal: alldata.parsedData?.codigoPostal,
              colonia: alldata.parsedData?.colonia,
              correo: alldata.inputInfo?.correo,
              creationDate: customDate,
              curp: alldata.parsedData?.curp,
              domicilio: alldata.parsedData?.domicilio,
              estado: alldata.parsedData?.estado,
              expedicionlicencia: new Date(customTimestamp).toISOString(), 
              expiracionlicencia: new Date(customTimestamp).toISOString(),
              fechanacimiento: new Date(customTimestamp).toISOString(), 
              ineAtrasPhoto: alldata.ineAtrasPhotoNombre,
              ineEnfrentePhoto: alldata.ineEnfrentePhotoNombre,
              insuranceCompany: alldata.aseguradora,
              linea: alldata.scrapedData?.linea,
              marca: alldata.scrapedData?.marca,
              modelo: alldata.scrapedData?.modelo,
              nombres: alldata.parsedData?.nombres,
              numerolicencia: "BC302405476649", 
              numeroPoliza: "0",
              origen: alldata.scrapedData?.origen,
              pagado: 0,
              pais: "Mexico",
              placa: alldata.plateNumber,
              polizaArchivo: "none.pdf",
              rfc: alldata.inputInfo?.rfc,
              serie: alldata.scrapedData?.serie,
              sex: 0,
              tarjetaCirculacionPhoto: alldata.circulacionPhotoNombre, 
              telefono: alldata.inputInfo?.telefono,
              version: alldata.scrapedData?.version,        });
            const response = await axios({
              method: "post",
              url: "https://www.gruporomeroseguros.com/api/drivers/",
              data: {
                apellidos: alldata.parsedData?.apellidos,
                cAmis: 0,
                cartype: alldata.tipovehiculo,
                ciudad: alldata.parsedData?.ciudad,
                cobertura: "Obligatoria",
                codigoPostal: alldata.parsedData?.codigoPostal,
                colonia: alldata.parsedData?.colonia,
                correo: alldata.inputInfo?.correo,
                creationDate: customDate,
                curp: "VERA960918HMNNML09",
                domicilio: alldata.parsedData?.domicilio,
                estado: alldata.parsedData?.estado,
                expedicionlicencia: new Date(customTimestamp).toISOString(), 
                expiracionlicencia: new Date(customTimestamp).toISOString(),
                fechanacimiento: new Date(customTimestamp).toISOString(), 
                ineAtrasPhoto: alldata.ineAtrasPhotoNombre,
                ineEnfrentePhoto: alldata.ineEnfrentePhotoNombre,
                insuranceCompany: alldata.aseguradora,
                linea: alldata.scrapedData?.linea,
                marca: alldata.scrapedData?.marca,
                modelo: alldata.scrapedData?.modelo,
                nombres: alldata.parsedData?.nombres,
                numerolicencia: "BC302405476649", 
                numeroPoliza: "0",
                origen: alldata.scrapedData?.origen,
                pagado: 0,
                pais: "Mexico",
                placa: alldata.plateNumber,
                polizaArchivo: "none.pdf",
                rfc: alldata.inputInfo?.rfc,
                serie: alldata.scrapedData?.serie,
                sex: gener,
                tarjetaCirculacionPhoto: alldata.circulacionPhotoNombre, 
                telefono: alldata.inputInfo?.telefono,
                version: alldata.scrapedData?.version,              
              },
            });
            console.log("API response:", response.data);
          }}
        >
          Pedir Turno
        </button>
      </>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#fff",
  },
  spinner: {
    width: "50px",
    height: "50px",
    border: "5px solid #f3f3f3",
    borderTop: "5px solid #0000ff",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  text: {
    marginTop: "20px",
    fontSize: "18px",
    color: "#333",
  },
};

export default UploadAndWait;
