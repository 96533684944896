import React, { createContext, useContext, useState } from 'react';

interface AppState {
    devices: MediaDeviceInfo[];
    setDevices: React.Dispatch<React.SetStateAction<MediaDeviceInfo[]>>;
    plateNumber: string | null;
    setPlateNumber: React.Dispatch<React.SetStateAction<string | null>>;
    tipovehiculo: string | null;
    settipovehiculo: React.Dispatch<React.SetStateAction<string | null>>;
    aseguradora: string | null;
    setaseguradora: React.Dispatch<React.SetStateAction<string | null>>;
    genero: string | null;
    setgenero: React.Dispatch<React.SetStateAction<string | null>>;
    scrapedData: any;
    setScrapedData: React.Dispatch<React.SetStateAction<any>>;
    circulacionPhoto: string | null;
    setCirculacionPhoto: React.Dispatch<React.SetStateAction<string | null>>;
    circulacionPhotoNombre: string | null;
    setCirculacionPhotoNombre: React.Dispatch<React.SetStateAction<string | null>>;
    ineAtrasPhoto: string | null;
    setIneAtrasPhoto: React.Dispatch<React.SetStateAction<string | null>>;
    ineAtrasPhotoNombre: string | null;
    setIneAtrasPhotoNombre: React.Dispatch<React.SetStateAction<string | null>>;
    ineEnfrentePhoto: string | null;
    setIneEnfrentePhoto: React.Dispatch<React.SetStateAction<string | null>>;
    ineEnfrentePhotoNombre: string | null;
    setIneEnfrentePhotoNombre: React.Dispatch<React.SetStateAction<string | null>>;
    inputInfo: { telefono: string; correo: string; rfc: string } | null;
    setInputInfo: React.Dispatch<React.SetStateAction<{ telefono: string; correo: string; rfc: string } | null>>;
    parsedData: any;
    setParsedData: React.Dispatch<React.SetStateAction<any>>;
  }
  
  const AppContext = createContext<AppState | undefined>(undefined);
  
  const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
      throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
  };
  
  const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [plateNumber, setPlateNumber] = useState<string | null>(null);
    const [scrapedData, setScrapedData] = useState<any>(null);
    const [circulacionPhoto, setCirculacionPhoto] = useState<string | null>(null);
    const [ineAtrasPhoto, setIneAtrasPhoto] = useState<string | null>(null);
    const [ineEnfrentePhoto, setIneEnfrentePhoto] = useState<string | null>(null);
    const [inputInfo, setInputInfo] = useState<{ telefono: string; correo: string; rfc: string } | null>(null);
    const [parsedData, setParsedData] = useState<any>(null);
    const [circulacionPhotoNombre, setCirculacionPhotoNombre] = useState<string | null>(null);
    const [ineAtrasPhotoNombre, setIneAtrasPhotoNombre] = useState<string | null>(null);
    const [ineEnfrentePhotoNombre, setIneEnfrentePhotoNombre] = useState<string | null>(null);
    const [tipovehiculo, settipovehiculo] = useState<string | null>(null);
    const [aseguradora, setaseguradora] = useState<string | null>(null);
    const [genero, setgenero] = useState<string | null>(null);
    return (
      <AppContext.Provider
        value={{
          devices,
          setDevices,
          plateNumber,
          setPlateNumber,
          tipovehiculo,
          settipovehiculo,
          aseguradora,
          setaseguradora,
          genero,
          setgenero,
          scrapedData,
          setScrapedData,
          circulacionPhoto,
          setCirculacionPhoto,
          circulacionPhotoNombre,
          setCirculacionPhotoNombre,
          ineEnfrentePhotoNombre,
          setIneEnfrentePhotoNombre,
          ineAtrasPhotoNombre,
          setIneAtrasPhotoNombre,
          ineAtrasPhoto,
          setIneAtrasPhoto,
          ineEnfrentePhoto,
          setIneEnfrentePhoto,
          inputInfo,
          setInputInfo,
          parsedData,
          setParsedData,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  };

export { AppProvider, useAppContext };