import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Header from '../components/Header';
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 80%;
  max-width: 400px;
  box-sizing: border-box;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 20px;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  margin-top: 20px;
`;

interface InputPlacasProps {
  onSubmit: (plateNumber: string, data: any) => void;
}

const InputPlacas: React.FC<InputPlacasProps> = ({ onSubmit }) => {
  const [plateNumber, setPlateNumber] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    console.log('Attempting to fetch data for plate number:', plateNumber);

    try {
      const response = await fetch('https://gruporomeroseguros.com/scrape', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ plateNumber }),
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Data received:', data);
      onSubmit(plateNumber, data);
    } catch (err) {
      console.error('Failed to fetch:', err);
      setError('Error. Por favor inténtelo nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Add the Header component with the back button */}
      <Header title="Placas"  />
      <Wrapper>
        <Title>Ingrese su número de placas</Title>
        <Input
          type="text"
          value={plateNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlateNumber(e.target.value)}
          placeholder="Número de placas"
        />
        <Button onClick={handleSubmit}>Ingresar</Button>
        {loading && <Spinner />}
        {error && <ErrorMessage>{error}</ErrorMessage> }
        {error&&<Button onClick={() => {navigate("/TarjetaCirculacion/")}}>Continuar a tomar foto de TarjetaCirculacion</Button>}
      </Wrapper>
    </>
  );
};
//        <Button >Continuar a ingresar datos manualmente ... en construccion</Button>
export default InputPlacas;