import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../AppContext";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;


const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;


const VehiculoEleccion: React.FC = () => {
  const navigate = useNavigate();
  const alldata = useAppContext();

  return (
    <>
      {/* Add the Header component with a back button */}
      <Header title="VehiculoEleccion" />
      <Wrapper>
        <Title>Elige tu vehiculo</Title>

        <ButtonGroup>
          <Button onClick={() => {
            alldata.settipovehiculo("Moto")
            navigate('/GeneroEleccion/')}}>Motocicleta</Button>
          <Button onClick={() => {
            alldata.settipovehiculo("Auto")
            navigate('/GeneroEleccion/')}}>Auto</Button>
          <Button onClick={() => {
            alldata.settipovehiculo("Pickup")
            navigate('/GeneroEleccion/')}}>Pickup</Button>
        </ButtonGroup>
      </Wrapper>
    </>
  );
};

export default VehiculoEleccion;
