import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header'; // Import the Header component
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px; /* Add padding to the wrapper */
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center; /* Center-align text */
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Message = styled.h2`
  font-size: 20px;
  color: #333;
  margin-top: 20px;
  text-align: center;
  padding: 20px; /* Add padding to the message */
  background-color: #fff; /* Add background color to the message */
  border-radius: 10px; /* Add border radius for rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
`;

interface EleccionInicialProps {
  onSelect: (selection: string) => void;
}

const EleccionInicial: React.FC<EleccionInicialProps> = ({ onSelect }) => {
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const handleSelect = (selection: string) => {
    if (selection === 'amplia') {
      setShowMessage(true);
    } else {
      onSelect(selection);
    }
  };

  return (
    <>
      {/* Add the Header component with a title and back button */}
      <Header title="Elección Inicial" />

      <Wrapper>
        {showMessage ? (
          <Message>Por favor acerquese a nuestro servicio a cliente para atenderlo.</Message>
        ) : (
          <>
            <Title>¿Qué tipo de póliza quiere?</Title>
            <Button onClick={() =>{ handleSelect('obligatoria');navigate('/AseguradoraEleccion/')}}>Obligatoria (para placas)</Button>
            <Button onClick={() => handleSelect('amplia')}>Cobertura Amplia</Button>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default EleccionInicial;
