import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header'; // Import the Header component

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const InfoBlock = styled.div`
  margin-bottom: 10px;
  width: 80%;
  max-width: 400px;
  box-sizing: border-box;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

const Data = styled.div`
  font-size: 16px;
  color: #555;
  word-wrap: break-word;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  max-width: 400px;
  margin-top: 20px;
`;

const Button = styled.button<{ color: string }>`
  background-color: ${(props) => props.color};
  color: ${(props) => (props.color === 'lightgray' ? '#333' : 'white')};
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: ${(props) => (props.color === 'lightgray' ? 'none' : '1px solid transparent')};
  width: 200px;
  margin: 0 10px;

  &:hover {
    background-color: ${(props) => (props.color === 'lightgray' ? '#bbb' : '#0056b3')};
  }
`;

interface InfoResultsProps {
  data: {
    telefono: string;
    correo: string;
    rfc: string;
    placa: string;
    serie: string;
    origen: string;
    marca: string;
    linea: string;
    version: string;
    modelo: string;
    tarjetaCirculacionPhoto: string | null;
    ineAtrasPhoto: string | null;
  };
  onModify: () => void;
  onConfirm: () => void;
}

const InfoResults: React.FC<InfoResultsProps> = ({ data, onModify, onConfirm}) => {

  return (
    <>
      {/* Add the Header component with the back button */}
      <Header title="Revisa tu información"  />
      <Wrapper>
        <Title>Revisa tu información</Title>
        <InfoBlock>
          <Label>Teléfono:</Label>
          <Data>{data.telefono}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>Correo:</Label>
          <Data>{data.correo}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>RFC:</Label>
          <Data>{data.rfc}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>Placa:</Label>
          <Data>{data.placa}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>Serie:</Label>
          <Data>{data.serie}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>Origen:</Label>
          <Data>{data.origen}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>Marca:</Label>
          <Data>{data.marca}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>Línea:</Label>
          <Data>{data.linea}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>Versión:</Label>
          <Data>{data.version}</Data>
        </InfoBlock>
        <InfoBlock>
          <Label>Modelo:</Label>
          <Data>{data.modelo}</Data>
        </InfoBlock>
        {data.tarjetaCirculacionPhoto && (
          <InfoBlock>
            <Label>Tarjeta de Circulación:</Label>
            <img src={data.tarjetaCirculacionPhoto} alt="Tarjeta de Circulación" style={{ width: '100%', maxWidth: '400px' }} />
          </InfoBlock>
        )}
        {data.ineAtrasPhoto && (
          <InfoBlock>
            <Label>INE (parte trasera):</Label>
            <img src={data.ineAtrasPhoto} alt="INE Atras" style={{ width: '100%', maxWidth: '400px' }} />
          </InfoBlock>
        )}
        <ButtonWrapper>
          <Button color="lightgray" onClick={onModify}>Modificar algún dato</Button>
          <Button color="green" onClick={onConfirm}>Confirmar</Button>
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

export default InfoResults;
